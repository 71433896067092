/* -------Avatar--------- */
.ns-avatar {
  border-width: 1px;
  border-style: solid;
  border-color: #e9ecef;
  overflow: hidden;
  flex-shrink: 0;
}

.ns-avatar-circle {
  border-radius: 50%;
}

.ns-avatar-labels {
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #333238;
}

.ns-avatar-label {
  font-weight: 600;
}

.ns-avatar-sublabel {
  color: #737278;
}

/* -------Badge--------- */
/* Basic badge styles */
.ns-badge {
  display: inline-flex;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  padding: 0.25rem 0.5rem;
}
.ns-badge.sm {
  padding-top: 0;
  padding-bottom: 0;
}
.ns-badge.md {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.ns-badge.lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
}
.ns-badge.pill {
  border-radius: 10rem;
}
.ns-badge.rect {
  border-radius: 3px;
}

/* Variants */
.ns-badge.neutral {
  background-color: #f8f9fa;
  color: #495057;
  text-decoration: none;
}

.ns-badge.info {
  background-color: #cfe2ff;
  color: #084298;
  text-decoration: none;
}

.ns-badge.success {
  background-color: #d1e7dd;
  color: #0f5132;
  text-decoration: none;
}

.ns-badge.warning {
  background-color: #ffe5d0;
  color: #984c0c;
  text-decoration: none;
}

.ns-badge.danger {
  background-color: #f8d7da;
  color: #842029;
  text-decoration: none;
}

/* -------Dropdown--------- */
/* Basic badge styles */
.ns-dropdown-button {
  border-width: 0;
  padding: 0.5rem 0.75rem;
  background-color: transparent;
  line-height: 1rem;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  display: flex;
}
.ns-dropdown-button.sm {
  padding-top: 0;
  padding-bottom: 0;
}
.ns-dropdown-button.md {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.ns-dropdown-button.lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
}

/* Variants */
.ns-dropdown-button.neutral {
  background-color: #f8f9fa;
  color: #495057;
}

.ns-dropdown-button.info {
  background-color: #0d6efd;
  color: #fff;
}

.ns-dropdown-button.success {
  background-color: #198754;
  color: #fff;
}

.ns-dropdown-button.warning {
  background-color: #fd7e14;
  color: #fff;
}

.ns-dropdown-button.danger {
  background-color: #dc3545;
  color: #fff;
}

.ns-dropdown {
  position: relative;
  display: inline-block;
}

.ns-dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  overflow: auto;
  box-shadow: 0 2px 8px #dee2e6, 0 0 2px #dee2e6;
  z-index: 1;
  border-width: 1px;
  border-style: solid;
  border-color: #bfbfc3;
  border-radius: 0.5rem;
  padding: 0.25rem;
  margin-top: 5px;
  min-width: 10rem;
}
.ns-dropdown-content.left {
  right: 0;
}
.ns-dropdown-content.right {
  left: 0;
}

/* -------Dropdown Item--------- */
.ns-dropdown-item {
  padding: 0.5rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  border: 0;
}
.ns-dropdown-item .ns-dropdown-labels {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #333238;
}

.ns-dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

/* -------Alert--------- */
/* Basic alert styles */
.ns-alert {
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400;
  padding: 0.5rem 1rem;
}
.ns-alert i {
  font-size: 1.25rem;
}
.ns-alert.message {
  padding: 0 0.5rem;
}
.ns-alert.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  border-radius: 0.5rem;
  margin-left: auto;
  padding: 0.375rem;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

/* Variants */
.ns-alert.info {
  background-color: #cfe2ff;
  color: #0d6efd;
}
.ns-alert.info i {
  color: #0d6efd;
}
.ns-alert.info.rounded {
  border-radius: 0.5rem !important;
}
.ns-alert.info.outlined {
  border: 1px solid #0d6efd !important;
  border-radius: 0.5rem !important;
}
.ns-alert.info.border-top {
  border-top: 4px solid #0d6efd !important;
}
.ns-alert.info.border-left {
  border-left: 4px solid #0d6efd !important;
}
.ns-alert.info .button:hover {
  background-color: #9ec5fe;
}

.ns-alert.success {
  background-color: #d1e7dd;
  color: #198754;
}
.ns-alert.success i {
  color: #198754;
}
.ns-alert.success.rounded {
  border-radius: 0.5rem !important;
}
.ns-alert.success.outlined {
  border: 1px solid #198754 !important;
  border-radius: 0.5rem !important;
}
.ns-alert.success.border-top {
  border-top: 4px solid #198754 !important;
}
.ns-alert.success.border-left {
  border-left: 4px solid #198754 !important;
}
.ns-alert.success .button:hover {
  background-color: #a3cfbb;
}

.ns-alert.warning {
  background-color: #fff3cd;
  color: #ffc107;
}
.ns-alert.warning i {
  color: #ffc107;
}
.ns-alert.warning.rounded {
  border-radius: 0.5rem !important;
}
.ns-alert.warning.outlined {
  border: 1px solid #ffc107 !important;
  border-radius: 0.5rem !important;
}
.ns-alert.warning.border-top {
  border-top: 4px solid #ffc107 !important;
}
.ns-alert.warning.border-left {
  border-left: 4px solid #ffc107 !important;
}
.ns-alert.warning .button:hover {
  background-color: #ffe69c;
}

.ns-alert.danger {
  background-color: #f8d7da;
  color: #dc3545;
}
.ns-alert.danger i {
  color: #dc3545;
}
.ns-alert.danger.rounded {
  border-radius: 0.5rem !important;
}
.ns-alert.danger.outlined {
  border: 1px solid #dc3545 !important;
  border-radius: 0.5rem !important;
}
.ns-alert.danger.border-top {
  border-top: 4px solid #dc3545 !important;
}
.ns-alert.danger.border-left {
  border-left: 4px solid #dc3545 !important;
}
.ns-alert.danger .button:hover {
  background-color: #f1aeb5;
}

/* -------Button--------- */
/* Basic button styles */
.ns-button {
  display: inline-flex;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  padding: 0.25rem 0.5rem;
  appearance: none;
  border: none;
  cursor: pointer;
}
.ns-button.sm {
  padding: 0.25rem 0.625rem;
  font-size: 0.813rem;
}
.ns-button.md {
  padding: 0.375rem 1rem;
  font-size: 0.875;
}
.ns-button.lg {
  padding: 0.5rem 1.375rem;
  font-size: 0.938rem;
}
.ns-button.pill {
  border-radius: 10rem;
}
.ns-button.rounded {
  border-radius: 0.2rem;
}
.ns-button.rect {
  border-radius: 0;
}

/* Colors */
.ns-button.neutral {
  background-color: #adb5bd;
  color: #fff;
}
.ns-button.neutral.text {
  background-color: transparent;
  color: #adb5bd;
}
.ns-button.neutral.contained {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}
.ns-button.neutral.outlined {
  background-color: transparent;
  border: 1px solid #adb5bd;
  color: #adb5bd;
}

.ns-button.info {
  background-color: #0d6efd;
  color: #fff;
}
.ns-button.info.text {
  background-color: transparent;
  color: #0d6efd;
}
.ns-button.info.contained {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}
.ns-button.info.outlined {
  background-color: transparent;
  border: 1px solid #0d6efd;
  color: #0d6efd;
}

.ns-button.success {
  background-color: #198754;
  color: #fff;
}
.ns-button.success.text {
  background-color: transparent;
  color: #198754;
}
.ns-button.success.contained {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}
.ns-button.success.outlined {
  background-color: transparent;
  border: 1px solid #198754;
  color: #198754;
}

.ns-button.warning {
  background-color: #fd7e14;
  color: #fff;
}
.ns-button.warning.text {
  background-color: transparent;
  color: #fd7e14;
}
.ns-button.warning.contained {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}
.ns-button.warning.outlined {
  background-color: transparent;
  border: 1px solid #fd7e14;
  color: #fd7e14;
}

.ns-button.danger {
  background-color: #dc3545;
  color: #fff;
}
.ns-button.danger.text {
  background-color: transparent;
  color: #dc3545;
}
.ns-button.danger.contained {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}
.ns-button.danger.outlined {
  background-color: transparent;
  border: 1px solid #dc3545;
  color: #dc3545;
}

/* -------Tooltip--------- */
.ns-tooltip-container {
  position: relative;
  display: inline-block;
}

.active {
  visibility: visible !important;
}

/* Variants */
.ns-tooltip.light {
  visibility: hidden;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  width: max-content;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  /* arrow */
  /* placement top */
  /* placement right */
  /* placement bottom */
  /* placement left */
}
.ns-tooltip.light::after {
  content: " ";
  position: absolute;
  border-width: 6px;
  border-style: solid;
}
.ns-tooltip.light.top {
  bottom: calc(100% + 6px);
  left: 50%;
  transform: translateX(-50%);
}
.ns-tooltip.light.top::after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: #fff transparent transparent transparent;
}
.ns-tooltip.light.right {
  left: calc(100% + 6px);
  top: 50%;
  transform: translateY(-50%);
}
.ns-tooltip.light.right::after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-color: transparent #fff transparent transparent;
}
.ns-tooltip.light.bottom {
  top: calc(100% + 6px);
  left: 50%;
  transform: translateX(-50%);
}
.ns-tooltip.light.bottom::after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: transparent transparent #fff transparent;
}
.ns-tooltip.light.left {
  right: calc(100% + 6px);
  top: 50%;
  transform: translateY(-50%);
}
.ns-tooltip.light.left::after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-color: transparent transparent transparent #fff;
}

.ns-tooltip.dark {
  visibility: hidden;
  background-color: #212529;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  width: max-content;
  /* arrow */
  /* placement top */
  /* placement right */
  /* placement bottom */
  /* placement left */
}
.ns-tooltip.dark::after {
  content: " ";
  position: absolute;
  border-width: 6px;
  border-style: solid;
}
.ns-tooltip.dark.top {
  bottom: calc(100% + 6px);
  left: 50%;
  transform: translateX(-50%);
}
.ns-tooltip.dark.top::after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: #212529 transparent transparent transparent;
}
.ns-tooltip.dark.right {
  left: calc(100% + 6px);
  top: 50%;
  transform: translateY(-50%);
}
.ns-tooltip.dark.right::after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-color: transparent #212529 transparent transparent;
}
.ns-tooltip.dark.bottom {
  top: calc(100% + 6px);
  left: 50%;
  transform: translateX(-50%);
}
.ns-tooltip.dark.bottom::after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: transparent transparent #212529 transparent;
}
.ns-tooltip.dark.left {
  right: calc(100% + 6px);
  top: 50%;
  transform: translateY(-50%);
}
.ns-tooltip.dark.left::after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-color: transparent transparent transparent #212529;
}

/* -------Carousel--------- */
.ns-carousel {
  position: relative;
  display: block;
}
.ns-carousel .button-control-prev,
.ns-carousel .button-control-next {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
.ns-carousel .button-control-prev.button-control-prev,
.ns-carousel .button-control-next.button-control-prev {
  left: 1rem !important;
}
.ns-carousel .button-control-prev.button-control-next,
.ns-carousel .button-control-next.button-control-next {
  left: initial;
  right: 1rem !important;
}
.ns-carousel .button-control-prev .arrow-icon,
.ns-carousel .button-control-next .arrow-icon {
  font-size: 30pt;
  color: rgba(204, 204, 204, 0.65);
}
.ns-carousel .button-control-prev .arrow-icon:hover,
.ns-carousel .button-control-next .arrow-icon:hover {
  color: #fff;
}
.ns-carousel .container-indicators {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 50%;
  bottom: 5%;
  list-style: none;
  z-index: 2;
  transform: translateX(-50%);
}
.ns-carousel .container-indicators .button-indicator {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: rgba(204, 204, 204, 0.65);
  margin: 0 5px;
  border: none;
  cursor: pointer;
}
.ns-carousel .container-indicators .button-indicator:hover {
  background: #fff;
}
.ns-carousel .container-indicators .button-indicator.button-indicator-select {
  background: #fff !important;
}
.ns-carousel .wrapper {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.ns-carousel .wrapper .item {
  color: #fff;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: ease-out 2s;
  position: absolute;
}
.ns-carousel .wrapper .item.show {
  opacity: 1 !important;
}
.ns-carousel .wrapper .item .item-content {
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}
.ns-carousel .wrapper .item .item-content.top {
  top: 7% !important;
}
.ns-carousel .wrapper .item .item-content.center {
  top: 50%;
  transform: translate(-50%, -50%);
}
.ns-carousel .wrapper .item .item-content.bottom {
  bottom: 15% !important;
}
.ns-carousel .wrapper .item .item-content .title {
  font-size: 20pt;
  letter-spacing: 1px;
  margin-top: 0;
  margin-bottom: 0.2rem;
}
.ns-carousel .wrapper .item .item-content .text {
  margin: 0;
  font-size: 12pt;
}
.ns-carousel .wrapper .item .item-image {
  background-repeat: no-repeat;
  object-fit: cover;
  background-position: center;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
}

/* -------Password--------- */
.ns-password {
  display: inline-block;
  position: relative;
}
.ns-password .input {
  display: block;
  width: 18rem;
  color: #4a5568;
  font-size: 0.875rem;
  border-radius: 0.375rem;
  padding: 1.2rem;
  background-color: #f7fafc;
}
.ns-password .input:focus {
  outline: none;
  border: 2px solid #4299e1;
}
.ns-password .toggle-password-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.2rem;
  padding: 0.625rem;
  background-color: #f7fafc;
  color: rgb(56, 55, 55);
  border: none;
  cursor: pointer;
}

/* -------Toast--------- */
.ns-toast {
  position: fixed;
  display: flex;
  align-items: center;
  width: 20rem;
  padding: 0.8rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  z-index: 1000;
}
.ns-toast .icon {
  padding: 0 0.5rem;
}
.ns-toast .span {
  padding-left: 0.5rem;
}
.ns-toast .button {
  position: static;
  display: inline-flex;
  width: 1rem;
  height: 1rem;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  box-shadow: none;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-left: auto;
  padding: 1rem;
}
.ns-toast.top-right {
  top: var(--toast-offset);
  right: 2rem;
}
.ns-toast.bottom-right {
  bottom: var(--toast-offset);
  right: 2rem;
}

/* Variants */
.ns-toast.info {
  background-color: #cfe2ff;
  color: #0d6efd;
}
.ns-toast.info.rounded {
  border-radius: 0.5rem;
}
.ns-toast.info.filled {
  background-color: #0d6efd;
  border-radius: 0.5rem;
  color: #fff !important;
}
.ns-toast.info.filled .button {
  color: inherit;
}
.ns-toast.info.filled .button:hover {
  background-color: #3d8bfd;
}
.ns-toast.info.outlined {
  border: 1px solid #0d6efd;
  border-radius: 0.5rem;
}
.ns-toast.info.border-top {
  border-top: 4px solid #0d6efd !important;
}
.ns-toast.info.border-left {
  border-left: 4px solid #0d6efd;
}
.ns-toast.info .button {
  color: #0d6efd;
}
.ns-toast.info .button:hover {
  background-color: #9ec5fe;
}
.ns-toast.info .span {
  border-left: 2px solid #9ec5fe;
}

.ns-toast.success {
  background-color: #d1e7dd;
  color: #198754;
}
.ns-toast.success.rounded {
  border-radius: 0.5rem;
}
.ns-toast.success.filled {
  background-color: #198754;
  border-radius: 0.5rem;
  color: #fff !important;
}
.ns-toast.success.filled .button {
  color: inherit;
}
.ns-toast.success.filled .button:hover {
  background-color: #479f76;
}
.ns-toast.success.outlined {
  border: 1px solid #198754;
  border-radius: 0.5rem;
}
.ns-toast.success.border-top {
  border-top: 4px solid #198754 !important;
}
.ns-toast.success.border-left {
  border-left: 4px solid #198754;
}
.ns-toast.success .button {
  color: #198754;
}
.ns-toast.success .button:hover {
  background-color: #a3cfbb;
}
.ns-toast.success .span {
  border-left: 2px solid #a3cfbb;
}

.ns-toast.warning {
  background-color: #fff3cd;
  color: #ffc107;
}
.ns-toast.warning.rounded {
  border-radius: 0.5rem;
}
.ns-toast.warning.filled {
  background-color: #ffc107;
  border-radius: 0.5rem;
  color: #fff !important;
}
.ns-toast.warning.filled .button {
  color: inherit;
}
.ns-toast.warning.filled .button:hover {
  background-color: #ffcd39;
}
.ns-toast.warning.outlined {
  border: 1px solid #ffc107;
  border-radius: 0.5rem;
}
.ns-toast.warning.border-top {
  border-top: 4px solid #ffc107 !important;
}
.ns-toast.warning.border-left {
  border-left: 4px solid #ffc107;
}
.ns-toast.warning .button {
  color: #ffc107;
}
.ns-toast.warning .button:hover {
  background-color: #ffe69c;
}
.ns-toast.warning .span {
  border-left: 2px solid #ffe69c;
}

.ns-toast.danger {
  background-color: #f8d7da;
  color: #dc3545;
}
.ns-toast.danger.rounded {
  border-radius: 0.5rem;
}
.ns-toast.danger.filled {
  background-color: #dc3545;
  border-radius: 0.5rem;
  color: #fff !important;
}
.ns-toast.danger.filled .button {
  color: inherit;
}
.ns-toast.danger.filled .button:hover {
  background-color: #e35d6a;
}
.ns-toast.danger.outlined {
  border: 1px solid #dc3545;
  border-radius: 0.5rem;
}
.ns-toast.danger.border-top {
  border-top: 4px solid #dc3545 !important;
}
.ns-toast.danger.border-left {
  border-left: 4px solid #dc3545;
}
.ns-toast.danger .button {
  color: #dc3545;
}
.ns-toast.danger .button:hover {
  background-color: #f1aeb5;
}
.ns-toast.danger .span {
  border-left: 2px solid #f1aeb5;
}

.ns-toast.dark {
  background-color: #e9ecef;
  color: #212529;
}
.ns-toast.dark.rounded {
  border-radius: 0.5rem;
}
.ns-toast.dark.filled {
  background-color: #212529;
  border-radius: 0.5rem;
  color: #fff !important;
}
.ns-toast.dark.filled .button {
  color: inherit;
}
.ns-toast.dark.filled .button:hover {
  background-color: #4d5154;
}
.ns-toast.dark.outlined {
  border: 1px solid #212529;
  border-radius: 0.5rem;
}
.ns-toast.dark.border-top {
  border-top: 4px solid #212529 !important;
}
.ns-toast.dark.border-left {
  border-left: 4px solid #212529;
}
.ns-toast.dark .button {
  color: #212529;
}
.ns-toast.dark .button:hover {
  background-color: #dee2e6;
}
.ns-toast.dark .span {
  border-left: 2px solid #dee2e6;
}

/* -------dropzone--------- */
.ns-dropzone {
  display: flex;
  width: 47rem;
  height: 18rem;
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 0 !important;
  min-width: 200px !important;
  min-height: 300px !important;
}
.ns-dropzone .message {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.ns-dropzone .message .icon {
  font-size: 2.5rem;
  color: #adb5bd;
}
.ns-dropzone .message .message-text {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
.ns-dropzone .message .message-text .font-semibold {
  font-weight: 600;
}
.ns-dropzone .message .helper-text {
  font-size: 0.8rem;
  text-transform: uppercase;
  color: #495057;
  margin: 0;
}
.ns-dropzone .container {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 8px;
  cursor: pointer;
  border: 2px dashed #d2d6dc;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}
.ns-dropzone .size, .ns-dropzone .preview-image .details-image .size, .ns-dropzone .preview-file .details-file .size {
  display: block;
  font-size: inherit;
  font-weight: 600;
  background-color: #fff;
  border-radius: 5px;
}
.ns-dropzone .filename, .ns-dropzone .preview-image .details-image .filename, .ns-dropzone .preview-file .details-file .filename {
  display: block;
  width: 90%;
  font-size: inherit;
  font-weight: 400;
  background-color: #fff;
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  padding: 2px;
}
.ns-dropzone .filename:hover, .ns-dropzone .preview-image .details-image .filename:hover, .ns-dropzone .preview-file .details-file .filename:hover {
  overflow: visible;
  z-index: 10;
}
.ns-dropzone .filename:hover span, .ns-dropzone .preview-image .details-image .filename:hover span, .ns-dropzone .preview-file .details-file .filename:hover span {
  background-color: #fff;
  border-radius: 5px;
  padding: 2.5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
}
.ns-dropzone .delete-button, .ns-dropzone .preview-image .details-image .delete-button, .ns-dropzone .preview-file .delete-button {
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 2px 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: inherit;
  font-size: inherit;
}
.ns-dropzone .delete-button:hover, .ns-dropzone .preview-image .details-image .delete-button:hover, .ns-dropzone .preview-file .delete-button:hover {
  background-color: #e35d6a;
}
.ns-dropzone .preview-file {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border-radius: 5px;
  background-color: white;
  box-shadow: -1px 1px 11px -1px rgba(205, 193, 193, 0.75);
  justify-content: center;
  align-items: center;
}
.ns-dropzone .preview-file .icon-container {
  display: flex;
  width: 100%;
  height: 40%;
  background-color: #fff;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  cursor: default;
}
.ns-dropzone .preview-file .icon-container .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--icon-size);
}
.ns-dropzone .preview-file .details-file {
  display: flex;
  width: 90%;
  height: 60%;
  flex-direction: column;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s linear;
  cursor: default;
  font-size: var(--font-size);
}
.ns-dropzone .preview-image {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  box-shadow: -1px 1px 11px -1px rgba(205, 193, 193, 0.75);
  border-radius: 5px;
}
.ns-dropzone .preview-image .image {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
}
.ns-dropzone .preview-image .image img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.ns-dropzone .preview-image .details-image {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  background: rgba(120, 120, 120, 0.7);
  transition: opacity 0.2s linear;
  z-index: auto;
  cursor: default;
  font-size: var(--font-size);
}
.ns-dropzone .preview-image .details-image .size {
  padding: 3px;
}
.ns-dropzone .preview-image .show {
  display: flex;
}
.ns-dropzone .drag-over {
  border: 2px dashed #007bff;
  background-color: #f8f9fa;
}
.ns-dropzone .grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, var(--preview-size, 120px));
  grid-auto-rows: var(--preview-size, 120px);
  gap: 20px;
}
.ns-dropzone .hidden {
  display: none;
}
.ns-dropzone .text-red-500 {
  color: #dc3545;
}
.ns-dropzone .text-green-500 {
  color: #198754;
}
.ns-dropzone .text-blue-500 {
  color: #0d6efd;
}
.ns-dropzone .text-orange-500 {
  color: #fd7e14;
}
.ns-dropzone .text-yellow-500 {
  color: #ffc107;
}
.ns-dropzone .text-purple-500 {
  color: #6f42c1;
}
.ns-dropzone .text-gray-500 {
  color: #adb5bd;
}

/* -------Timepicker--------- */
.ns-timepicker {
  display: block;
  width: min-content;
}
.ns-timepicker .time-container {
  position: relative;
}
.ns-timepicker .time-container .time-input {
  display: block;
  width: 18rem;
  color: #4a5568;
  font-size: 0.875rem;
  border-radius: 0.375rem;
  padding: 1.2rem;
  padding-inline-end: 2rem;
  background-color: #f7fafc;
}
.ns-timepicker .time-container .time-input:focus {
  outline: none;
  border: 2px solid #4299e1;
}
.ns-timepicker .time-container .icon-time {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.2rem;
  background-color: transparent;
  color: #495057;
  margin-right: 0.3rem;
  padding: 0.2rem;
}

.timepicker-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.time-wrapper {
  box-sizing: content-box;
  min-width: 300px;
  min-height: 450px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Roboto, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
}

.time-header {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0d6efd;
}

.header-content {
  display: flex;
  font-size: 3em;
  color: #fff;
}
.header-content .hour {
  margin: 0 2px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.header-content .minute {
  margin: 0 2px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.time-aligner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}

.time-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  color: #0d6efd;
  cursor: pointer;
  font-size: 1rem;
  margin: 0 2rem;
  -webkit-tap-highlight-color: transparent;
}
.time-button:hover {
  background-color: #e9ecef;
}
.time-button_active {
  background-color: #0d6efd;
  color: white;
}
.time-button_active:hover {
  background-color: #0d6efd;
}

.ns-clock-wrapper {
  flex-grow: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ns-clock {
  position: relative;
  border-radius: 100%;
  background-color: #e9ecef;
  height: 240px;
  width: 240px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.ns-clock-item {
  position: absolute;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  text-align: center;
  padding: 10px;
  user-select: none;
  cursor: pointer;
  font-size: 1.1em;
  z-index: 3;
}

.ns-clock-outer {
  position: absolute;
  width: 4px;
  height: 4px;
  border: 18px solid #1976d2;
  background: whitesmoke;
  color: whitesmoke;
  border-radius: 100%;
  visibility: hidden;
  z-index: 2;
}

.minute-selected {
  visibility: visible;
}

.ns-middle-dot {
  z-index: 2;
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 7px;
  border-radius: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0d6efd;
}

.ns-hand-clock {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: rotate(-90deg);
  transform-origin: left center;
  height: 3px;
  width: 90px;
  background-color: #0d6efd;
}

.ns-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 0.5;
}
.ns-buttons .ns-button {
  font-weight: 300;
  font-size: 0.9rem;
  border: none;
  border-radius: 5px;
  background: transparent;
  color: #0d6efd;
  margin-right: 16px;
  padding: 8px;
  -webkit-tap-highlight-color: transparent;
}
.ns-buttons .ns-button:hover {
  cursor: pointer;
  background-color: #e9ecef;
}

.clock-item-active {
  background-color: #0d6efd;
  color: #fff;
}

.clock-minute-active {
  border-color: #0d6efd;
  color: #fff;
}

@media screen and (orientation: landscape) and (max-height: 450px) {
  .time-wrapper {
    flex-direction: row;
    min-width: 320px;
    min-height: 300px;
  }
  .ns-clock-wrapper {
    margin-left: 2rem;
  }
  .ns-buttons {
    flex-direction: column;
  }
}
.over-stretch {
  z-index: 1000;
}

/* Text Colors */
.text-neutral {
  color: #495057;
}

.text-info {
  color: #084298;
}

.text-success {
  color: #0f5132;
}

.text-warning {
  color: #984c0c;
}

.text-danger {
  color: #842029;
}
